import { render, staticRenderFns } from "./Newsletter.vue?vue&type=template&id=9a5d587a&"
import script from "./Newsletter.vue?vue&type=script&lang=js&"
export * from "./Newsletter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,OpusRenderInput: require('/~/src/modules/opus/components/Form/RenderInput.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default})
