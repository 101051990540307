
import { validateEmail } from '@/plugins/validator';
export default {
    data() {
        const email = this.$store.state.auth.user && this.$store.state.auth.user.email ? this.$store.state.auth.user.email : '';
        const subscribeFormKey = this.$config.IS_HANSEL ? '4ik4ogcV1c6sR' : 'EuDTloLE2OHjG';
        const subscribeFormUrl = `https://suomen-medituote-oy.creamailer.fi/tilaa/${subscribeFormKey}`;

        return {
            form: { userEmail: email },
            action: subscribeFormUrl,
        };
    },
    computed: {
        valid() {
            return validateEmail(this.form.userEmail);
        },
    },
};
