
import CartIcon from '~/assets/icon/cart-preview.svg?inline';
import CartIconOutOfStock from '~/assets/icon/cart-outofstock.svg?inline';
export default {
    components: { CartIcon, CartIconOutOfStock },
    props: {
        product: {
            type: Object,
            required: true,
        },
        outOfStock: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            errors: [],
            disabled: [],
            qty: 1,
            previewEnabled: true,
        };
    },
    computed: {
        minimalGroupedPrice() {
            const prices = this.product.items
                .filter((item) => item.product?.price_range?.minimum_price?.final_price?.value)
                .map((item) => {
                    return {
                        final: item.product.price_range.minimum_price.final_price.value,
                        regular: item.product.price_range.minimum_price.regular_price.value,
                    };
                })
                .sort((priceA, priceB) => priceA.final - priceB.final);

            const [cheapestProduct] = prices;
            cheapestProduct.hasDiscount = cheapestProduct.final !== cheapestProduct.regular;

            return cheapestProduct;
        },
        finalPrice() {
            return this.$root.$options.filters.currencyValue(this.qty * this.product.price_range.minimum_price.final_price.value);
        },
        regularPrice() {
            return this.$root.$options.filters.currencyValue(this.qty * this.product.price_range.minimum_price.regular_price.value);
        },
        showFullPrice() {
            if (!this.$store.state.auth.loggedIn || !this.$store.state.auth.user) {
                return true;
            }

            return this.$store.state.auth.user.is_allowed_to_view_regular_price;
        },
    },
    methods: {
        async submit(recursion) {
            if (this.previewEnabled) {
                await this.$store.dispatch('catalog/setProduct', this.product.url_key);
                await this.$store.dispatch('catalog/open');

                return;
            }

            const payload = {
                sku: this.product.sku,
                quantity: 1,
            };
            this.errors = [];
            try {
                // eslint-disable-next-line camelcase
                const { user_errors } = await this.$store.dispatch('cart/addProduct', {
                    items: [payload],
                    product: this.product,
                });
                // eslint-disable-next-line camelcase
                this.errors = user_errors;
                if (!this.errors.length) {
                    await this.$store.dispatch('cart/openCart', true);
                }
            } catch (e) {
                if (recursion) {
                    if (
                        e.message.startsWith('GraphQL error: Could not find a cart with ID') ||
                        e.message.startsWith('GraphQL error: The current user cannot perform operations on cart')
                    ) {
                        this.$storage.removeCookie('cart_id');
                        await this.$store.commit('cart/setCart', {});
                        await this.$store.dispatch('cart/getCart');
                        await this.submit(true);
                    }
                } else {
                    this.$notify.error(e);
                }
            }
        },
    },
};
