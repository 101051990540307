
// import query from '~/queries/getCategory.gql.js';

export default {
    props: {
        uid: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            products: [],
            category: {},
            total: 0,
            filters: [],
            toolbar: {},
            filtersOpen: false,
            sorts: {},
            search: '',
            readMoreMaxLength: 255,
            banner: null,
            seo: null,
            mostPopular: null,
        };
    },
    async fetch() {
        const id = this.uid || undefined;
        this.search = this.$route.query.q;
        let filter = {};

        /* Get filters and pagination params based on query filters
         * @example host:varaosat/apple/iphone-8?currentPage=1
         *  */
        filter = this.createFilterObject(this.$route.query);

        /* If filter object now has category_uid, it means client is trying to use category filters inside a category
         *  If not, client is trying to view a category page.
         *  */
        if (Object.prototype.hasOwnProperty.call(filter, 'category_uid')) {
            filter.category_uid.in.push(id);
        } else {
            // eslint-disable-next-line camelcase
            filter.category_uid = { eq: id };
        }
        const variables = { id, filter };
        if (this.$route.query.pageSize) {
            variables.pageSize = this.$route.query.pageSize;
        }
        if (this.$route.query.currentPage) {
            variables.currentPage = this.$route.query.currentPage;
        }
        if (this.$route.query.sortType && this.$route.query.sortDir) {
            const obj = {};
            obj[this.$route.query.sortType] = this.$route.query.sortDir;
            variables.sort = obj;
        }
        if (this.search || Object.prototype.hasOwnProperty.call(this.$route.query, 'q')) {
            delete variables.id;
            delete variables.filter.category_uid;
            delete variables.filter.q;
            variables.search = this.search;
        }

        const { data, errors } = await this.$axios.$get(`/category/${id}`, {
            params: variables,
        });

        if (errors) {
            if (
                errors.some((error) => error.message.startsWith('currentPage value')) &&
                this.$route.query.currentPage &&
                this.$route.query.currentPage !== '1'
            ) {
                const query = { ...this.$route.query };
                query.currentPage = '1';
                await this.$router.push({ ...this.$route, query });

                return;
            }
        }

        this.products = data.products.items.filter((item) => item !== null);
        [this.category] = data.categories.items;
        this.total = data.products.total_count;
        if (!this.filters.length) {
            this.filters = data.products.aggregations;
        }
        this.toolbar = data.products.page_info;
        this.sorts = data.products.sort_fields;

        if (!this.category.is_hide_highlights && id) {
            try {
                const mostPopular = await this.$axios.get(`/mostpopular/${id}`);
                if (mostPopular?.data?.data?.products?.items) {
                    this.mostPopular = mostPopular.data.data.products.items.map((item) => item.sku);
                }
            } catch (e) {}
        }

        if (this.$gtm) {
            try {
                this.$gtm.push({ ecommerce: null, items: null });
                this.$gtm.push({
                    event: 'view_item_list',
                    /* eslint-disable camelcase */
                    ecommerce: {
                        item_list_id: this.uid,
                        item_list_name: this.category.name,
                        items: [...data.products.items].reduce((acc, curr) => {
                            acc.push({
                                item_id: curr.sku,
                                item_name: curr.name,
                                price: curr.price_range.minimum_price.final_price.value,
                            });

                            return acc;
                        }, []),
                    },
                    items: [...data.products.items].reduce((acc, curr) => {
                        acc.push({
                            id: curr.sku,
                            google_business_vertical: 'retail',
                        });

                        return acc;
                    }, []),
                    /* eslint-enable camelcase */
                });
            } catch (e) {
                console.error(e);
            }
        }

        const path = this.$route.path;

        const categoryBanners = await this.$strapi.getEntries({
            contentType: 'category-banners',
            filters: {
                scope: {
                    $eq: path,
                },
            },
            populate: ['image'],
        });

        if (categoryBanners.data && categoryBanners.data.length) {
            this.banner = categoryBanners.data[0].attributes;
        }

        const seoData = await this.$strapi.getEntries({
            contentType: 'seo-texts',
            filters: {
                scope: {
                    $eq: path,
                },
            },
        });

        if (seoData.data && seoData.data.length) {
            this.seo = seoData.data[0].attributes;
        }
    },
    fetchKey: 'category-page',
    head() {
        const title = this.category.meta_title || this.category.name;

        const meta = [
            {
                hid: 'og:title',
                name: 'og:title',
                content: this.search ? `${this.$t('navigation.title')}: "${this.search}"` : title,
            },
        ];

        if (this.category && !this.$fetchState.pending && !this.$fetchState.error && this.category.image) {
            meta.push({
                hid: 'og:image',
                name: 'og:image',
                content: this.category && !this.$fetchState.pending && !this.$fetchState.error && this.category.image,
            });
        }

        const description =
            (this.category || (!this.$fetchState.pending && !this.$fetchState.error)) && (this.category.meta_description || this.category.description);

        if (description) {
            meta.push(
                {
                    hid: 'description',
                    name: 'description',
                    content: description,
                },
                {
                    hid: 'og:description',
                    name: 'og:description',
                    content: description,
                },
            );
        }

        return {
            title: this.search ? `${this.$t('navigation.title')}: "${this.search}"` : title,
            titleTemplate: '%s',
            meta,
            link: [{ rel: 'canonical', href: this.$config.HOSTNAME + this.$route.path }],
        };
    },
    computed: {
        filterParams() {
            const filters = {};
            this.filters.forEach((item) => {
                const split = item.split('-');
                if (!filters[split[0]]) {
                    filters[split[0]] = [split[1]];
                } else {
                    const newFilter = filters[split[0]];
                    newFilter.push(split[1]);
                    filters[split[0]] = newFilter;
                }
            });

            return filters;
        },
        enabledFilters() {
            const enabledFilters = this.createFilterObject(this.$route.query);
            const filtersCount = Object.keys(enabledFilters).length;

            if (filtersCount === 0) {
                return '';
            }

            return `(${filtersCount})`;
        },
        parentCategoryUid() {
            if (this.category && this.category.breadcrumbs && this.category.breadcrumbs.length) {
                const latestIndex = this.category.breadcrumbs.length - 1;

                return this.category.breadcrumbs[latestIndex].category_uid;
            } else if (this.category && this.category.level <= 2) {
                return 'ROOT';
            } else {
                return null;
            }
        },
        currentCategoryUid() {
            return this.uid ? String(this.uid) : '';
        },
        showFilters() {
            if (this.search) {
                return false;
            }

            if (!this.category) {
                return false;
            }

            return Number(this.category.children_count) === 0;
        },
    },
    watch: {
        '$route.query'() {
            if (this.$route.name.includes('all') || this.$route.name.includes('search')) {
                this.$fetch();
            }
        },
        $route() {
            this.closeModal();
        },
    },
    mounted() {
        if (this.products.length === 0 && this.$route.query.currentPage !== '1') {
            this.$fetch();
        }
    },
    beforeMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        async closeModal() {
            await this.$store.dispatch('catalog/close');
        },
        onWindowResize() {
            this.recalculateDescriptionMaxLength();
        },
        recalculateDescriptionMaxLength() {
            const desktopDescriptionLength = 600;
            const mobileDescriptionLength = 255;

            if (this.$bps.isScreenMinWidth(this.$bps.screens.lg)) {
                this.readMoreMaxLength = desktopDescriptionLength;
            } else {
                this.readMoreMaxLength = mobileDescriptionLength;
            }
        },
        createFilterObject(filters) {
            const sorts = ['pageSize', 'currentPage', 'sortType', 'sortDir'];

            return Object.entries(filters).reduce((result, [filterName, selected]) => {
                if (sorts.includes(filterName)) {
                    return {
                        ...result,
                    };
                }
                if (filterName === 'price' || filterName.includes('bucket') || filterName.to) {
                    let from, to;
                    if (Array.isArray(selected)) {
                        [from, to] = selected[0].split('_'); //eslint-disable-line
                    } else {
                        [from, to] = selected.split('_');
                    }
                    if (to === '*') to = '9999999999999';
                    let name = filterName;
                    if (filterName.includes('bucket')) {
                        name = filterName.replace('_bucket', '');
                    }

                    result[name] = {
                        to,
                        from,
                    };

                    return {
                        ...result,
                        [name]: {
                            to,
                            from,
                        },
                    };
                }

                return {
                    ...result,
                    [filterName]: {
                        in: selected,
                    },
                };
            }, {});
        },
        onPaginationUpdate(index) {
            const { query } = this.$route;
            delete query.currentPage;
            this.$scrollTo('#category-products');
            this.$router.push({ path: this.$route.path, query: { currentPage: index, ...query } });
        },
    },
};
