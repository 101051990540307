
export default {
    model: {
        prop: 'checked',
        event: 'change',
    },
    props: {
        checked: {
            type: [Array, Boolean],
            default: false,
        },
        value: {
            type: [Boolean, String, null],
            default: null,
        },
    },
    data() {
        return {
            invalid: false,
        };
    },
    computed: {
        proxyChecked: {
            get() {
                return this.checked;
            },
            set(val) {
                this.$emit('change', val);
            },
        },
    },
    methods: {
        onInvalid() {
            this.invalid = true;
        },
        onBlur() {
            this.$el.checkValidity();
        },
    },
};
