
export default {
    middleware: ['loginRedirect'],

    async asyncData(context) {
        const { error, $axios, route, $strapi } = context;
        const metaPath = route.path;
        let path = route.params.pathMatch;
        if (path.startsWith('/')) {
            path = path.substring(1);
        }
        if (!path) {
            path = 'homePage';
        }
        try {
            const data = await $axios.$get(`/route/fi/${path}`);

            const pageInfo = await $strapi.getEntries({
                contentType: 'page-infos',
                filters: {
                    scope: {
                        $eq: metaPath,
                    },
                },
                populate: ['meta', 'meta.ogImage'],
            });

            if (pageInfo.data && pageInfo.data.length && pageInfo.data[0] && pageInfo.data[0].attributes.meta?.data?.attributes) {
                data.meta = pageInfo.data[0].attributes.meta.data.attributes;
            } else {
                data.meta = {};
            }

            return data;
        } catch (e) {
            return error({ statusCode: 404, message: 'Page not found' });
        }
    },
    head(context) {
        const title = this.meta?.title || this.$t('index.title');
        const meta = [];

        if (this.meta.title) {
            meta.push({
                hid: 'title',
                name: 'title',
                content: this.meta.title,
            });
        } else {
            meta.push({
                hid: 'og:title',
                name: 'og:title',
                content: this.$t('index.title'),
            });
        }

        if (this.meta.ogTitle) {
            meta.push({
                hid: 'og:title',
                name: 'og:title',
                content: this.meta.ogTitle,
            });
        }

        if (this.meta.ogDescription) {
            meta.push({
                hid: 'og:description',
                name: 'og:description',
                content: this.meta.ogDescription,
            });
            meta.push({
                hid: 'description',
                name: 'description',
                content: this.meta.ogDescription,
            });
        }

        if (this.meta.keywords) {
            meta.push({
                hid: 'keywords',
                name: 'keywords',
                content: this.meta.keywords,
            });
        }

        if (this.meta.ogImage?.data?.attributes?.url) {
            meta.push({
                hid: 'og:image',
                name: 'og:image',
                content: this.meta.ogImage?.data?.attributes?.url,
            });
        }

        return {
            title,
            meta,
        };
    },
};
