
import query from '~/queries/newProducts.graphql';

export default {
    props: {
        id: {
            type: Number,
            required: false,
            default: 0,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        titleCentered: {
            type: Boolean,
            required: false,
            default: false,
        },
        linkUrl: {
            type: String,
            required: false,
            default: '',
        },
        linkTitle: {
            type: String,
            required: false,
            default: '',
        },
        padding: {
            type: Boolean,
            required: false,
            default: false,
        },
        skus: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            products: [],
        };
    },
    async fetch() {
        const {
            data: {
                products: { items },
            },
        } = await this.$apollo.query({
            query,
            variables: { skus: this.skus },
        });
        this.products = items.sort((productA, productB) => {
            const skuA = productA.sku;
            const skuB = productB.sku;
            const indexA = this.skus.indexOf(skuA);
            const indexB = this.skus.indexOf(skuB);

            return indexA - indexB;
        });
    },
    fetchOnServer: false,
    fetchKey: 'newproducts',
    methods: {
        async closeModal() {
            await this.$store.dispatch('catalog/close');
        },
    },
};
