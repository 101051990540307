
export default {
    props: {
        /**
         * Current page
         */
        currentPage: {
            type: Number,
            default: 1,
            required: false,
        },
        /**
         * Total number of pages
         */
        totalPages: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            max: 5,
        };
    },
    computed: {
        pages() {
            const page = [...Array(this.totalPages).keys()].map((x) => ++x); // eslint-disable-line
            const len = page.length;
            if (len > this.max) {
                /* eslint-disable no-magic-numbers */
                const first = [...page].slice(0, 3);
                const last = [...page].slice(len - 3, len);

                const currentFirst = Array.from(Array(3).keys())
                    .map((x, index) => this.computedCurrPage + (index + 1))
                    .filter((item) => !first.includes(item))
                    .filter((item) => !last.includes(item))
                    .filter((item) => item <= len)
                    .filter((item) => item !== this.computedCurrPage);
                const currentPrev = Array.from(Array(3).keys())
                    .map((x, index) => this.computedCurrPage - (index + 1))
                    .sort((a, b) => a - b)
                    .filter((item) => !first.includes(item))
                    .filter((item) => item !== this.computedCurrPage)
                    .filter((item) => item > 0);
                const pages = [
                    ...first,
                    currentPrev.length ? '...' : null,
                    ...currentPrev,
                    !first.includes(this.computedCurrPage) && !last.includes(this.computedCurrPage) ? this.computedCurrPage : null,
                    ...currentFirst,
                    last.includes(this.computedCurrPage) ? null : '...',
                    ...last,
                ].filter((item) => item);
                /* eslint-enable no-magic-numbers */

                return pages.reduce((acc, curr) => (acc.includes(curr) && curr !== '...' ? acc : [...acc, curr]), []);
            }

            return page;
        },
        computedCurrPage() {
            if (this.$route.query && this.$route.query.currentPage) {
                return parseInt(this.$route.query.currentPage);
            }

            return this.currentPage;
        },
        getPrevPageNum() {
            return this.computedCurrPage - 1;
        },
        getNextPageNum() {
            return this.computedCurrPage + 1;
        },
        isPrevDisabled() {
            return this.computedCurrPage - 1 <= 0;
        },
        isNextDisabled() {
            return this.computedCurrPage + 1 > this.totalPages;
        },
    },
    methods: {
        updateCurrentPage(index) {
            this.$emit('update', index);
        },
    },
};
