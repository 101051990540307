
import { mapGetters } from 'vuex';

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        closeModalText: {
            type: String,
            required: false,
            default: 'Sulje',
        },
        open: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            cartOpen: 'cart/open',
        }),
    },
    methods: {
        hide(event) {
            if (event.target.dataset.closetrigger) {
                this.$emit('close');
            }

            // Close icon
            if (event.target.tagName === 'path' && event.target.parentNode.tagName === 'g' && event.target.parentNode.parentNode.dataset.closetrigger) {
                this.$emit('close');
            }
        },
    },
};
