
export default {
    props: {
        parentCategory: {
            type: String,
            required: true,
        },
        currentCategory: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            categories: [],
        };
    },
    async fetch() {
        if (!this.parentCategory) {
            return;
        }

        const {
            data: {
                categories: { items },
            },
        } = await this.$axios.$get(`/categorytreemenu/${this.parentCategory}`);

        if (items && items.length && items[0].children && items[0].children.length) {
            this.categories = items[0].children;
        }
    },
    methods: {
        productsCount(category) {
            return category.children.map((subCategory) => subCategory.product_count).reduce((a, b) => a + b, 0);
        },
    },
};
