
export default {
    fetchOnServer: true,
    data() {
        return {
            title: null,
            description: null,
            content: null,
            image: null,
        };
    },
    async fetch() {
        const path = this.$route.path;

        const pageData = await this.$strapi.getEntries({
            contentType: 'static-pages',
            filters: {
                urlKey: {
                    $eq: path,
                },
            },
            populate: ['image'],
        });

        if (pageData.data && pageData.data.length && pageData.data[0]) {
            this.title = pageData.data[0].attributes.title;
            this.description = pageData.data[0].attributes.description || '';
            this.content = pageData.data[0].attributes.content;
            if (pageData.data[0].attributes.image?.data?.attributes?.url) {
                this.image = pageData.data[0].attributes.image.data.attributes.url;
            }
        } else {
            this.$nuxt.error({ statusCode: 404, message: 'Page not found' });
        }
    },
    head() {
        const meta = [
            {
                hid: 'title',
                name: 'title',
                content: this.title,
            },
            {
                hid: 'og:title',
                name: 'og:title',
                content: this.title,
            },
            {
                hid: 'description',
                name: 'description',
                content: this.description,
            },
            {
                hid: 'og:description',
                name: 'og:description',
                content: this.description,
            },
        ];

        if (this.image) {
            meta.push({
                hid: 'og:image',
                name: 'og:image',
                content: this.image,
            });
        }

        return {
            title: this.title,
            meta,
        };
    },
};
