import { render, staticRenderFns } from "./HighlightedCategories.vue?vue&type=template&id=6661cbf4&"
import script from "./HighlightedCategories.vue?vue&type=script&lang=js&"
export * from "./HighlightedCategories.vue?vue&type=script&lang=js&"
import style0 from "./HighlightedCategories.vue?vue&type=style&index=0&id=6661cbf4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default,OpusLink: require('/~/src/modules/opus/components/Link.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,Slider: require('/~/src/components/Slider.vue').default})
