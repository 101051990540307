
export default {
    props: {
        uid: {
            type: String,
            required: true,
        },
    },
    data() {
        return { crumbs: [], showImage: true };
    },
    async fetch() {
        if (this.uid) {
            const {
                data: {
                    categories: { items },
                },
            } = await this.$axios.$get(`/categorytree/${this.uid}`);
            this.crumbs = items;
        }
    },
    fetchOnServer: true,
};
