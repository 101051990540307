
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hover: false,
            configurableImage: null,
        };
    },
    computed: {
        outOfStock() {
            const isGrouped = this.product.items && this.product.items.length;

            if (isGrouped) {
                if (this.product.stock_status === 'OUT_OF_STOCK') {
                    return true;
                }

                const stockStatuses = this.product.items.map((item) => item.product.stock_status);
                const isChildInStock = stockStatuses.some((status) => status === 'IN_STOCK');

                return !isChildInStock;
            } else {
                return this.product.stock_status === 'OUT_OF_STOCK';
            }
        },
        manufacturer() {
            if (this.product && this.product.custom_attributes) {
                const brandAttribute = this.product.custom_attributes.find((attribute) => attribute.attribute_metadata.code === 'manufacturer');
                if (brandAttribute?.selected_attribute_options?.attribute_option[0]) {
                    return brandAttribute.selected_attribute_options.attribute_option[0].label;
                }
            }

            return null;
        },
        image() {
            if (this.configurableImage) {
                return this.configurableImage;
            }
            const images = [...this.product.media_gallery];
            const last = images.pop();
            if (!this.hover) {
                return this.product.small_image.url;
            }

            return last ? last.url : this.product.small_image.url ? this.product.small_image.url : '';
        },
        isMedituoteBadge() {
            if (this.$config.IS_HANSEL) {
                const isMedituoteCatalog = Boolean(this.$storage.getCookie('medituote-catalog'));

                return isMedituoteCatalog;
            } else {
                return true;
            }
        },
    },
    methods: {
        stopPropagation(e) {
            e.stopPropagation();
        },
        setCurrentProductImage(payload) {
            this.configurableImage = payload?.product?.image?.url;
        },
        onHover() {
            this.hover = true;
        },
        onMouseOut() {
            this.hover = false;
        },
    },
};
