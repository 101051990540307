
import 'vue-glide-js/dist/vue-glide.css';
import { Glide, GlideSlide } from 'vue-glide-js';

export default {
    components: {
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide,
    },
    data() {
        return {
            slide: 0,
        };
    },
    render(h) {
        const data = {
            attrs: this.$attrs,
            value: this.key,
        };
        const list = [];
        this.$slots.default.forEach((element, index) => {
            if (index) {
                list.push(h('vue-glide-slide', { key: index }, [element]));
            }
        });
        const r = h('vue-glide', data, [h('template', { slot: 'control' }, this.$slots.control), list]);

        return r;
    },
};
