
export default {
    fetchOnServer: true,
    props: {
        route: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            materials: [],
            categories: [],
            filter: [],
            pageSize: 10,
            total: 0,
            currentPage: 1,
            singleMaterial: null,
            prev: null,
            next: null,
            heroBanner: null,
            indexBreadCrumbs: {
                crumbs: [
                    {
                        category_name: this.$t('materials.breadcrumbs.index'),
                        category_url_path: '/',
                        directLink: true,
                    },
                ],
                product: this.$t('materials.breadcrumbs.materials'),
            },
            materialBreadCrumbs: null,
        };
    },
    async fetch() {
        if (this.$config.IS_MEDITUOTE) {
            this.$nuxt.context.redirect({ path: '/' });
            return;
        }

        if (this.isIndexPage) {
            if (this.$route.query.pageSize) {
                this.pageSize = parseInt(this.$route.query.pageSize);
            }

            if (this.$route.query.currentPage) {
                this.currentPage = parseInt(this.$route.query.currentPage);
            }

            if (this.$route.query.category) {
                this.filter = [this.$route.query.category];
                const query = Object.assign({}, this.$route.query);
                delete query.category;
                await this.$router.replace({ query });
            }

            const categories = await this.$strapi.getEntries({
                contentType: 'hansel-material-categories',
            });

            if (categories.data && categories.data.length) {
                this.categories = categories.data.map((category) => category.attributes.label);
            }

            const filters = this.filter.length
                ? {
                      categories: {
                          label: {
                              $contains: this.filter[0],
                          },
                      },
                  }
                : undefined;

            const materials = await this.$strapi.getEntries({
                contentType: 'hansel-materials',
                pagination: {
                    pageSize: this.pageSize,
                    page: this.currentPage,
                },
                sort: 'publishedAt:desc',
                filters,
                populate: ['image', 'categories'],
            });

            this.total = materials.meta.pagination.pageCount;
            this.materials = materials.data;

            const heroBanners = await this.$strapi.getEntries({
                contentType: 'hero-banners',
                filters: {
                    scope: {
                        $eq: '/materiaalit',
                    },
                },
                populate: ['image'],
            });
            if (heroBanners.data && heroBanners.data.length) {
                [this.heroBanner] = heroBanners.data;
            }
        } else {
            const material = await this.$strapi.getEntries({
                contentType: 'hansel-materials',
                filters: {
                    urlKey: {
                        $containsi: this.route,
                    },
                },
                populate: ['image', 'categories', 'meta', 'meta.ogImage'],
            });

            if (material.data && material.data.length === 1) {
                [this.singleMaterial] = material.data;

                const next = await this.$strapi.getEntries({
                    contentType: 'hansel-materials',
                    pagination: {
                        pageSize: 1,
                        page: 1,
                    },
                    filters: {
                        publishedAt: {
                            $gt: material.data[0].attributes.publishedAt,
                        },
                    },
                    sort: 'publishedAt:asc',
                });

                if (next.data && next.data.length === 1) {
                    [this.next] = next.data;
                }

                const prev = await this.$strapi.getEntries({
                    contentType: 'hansel-materials',
                    pagination: {
                        pageSize: 1,
                        page: 1,
                    },
                    filters: {
                        publishedAt: {
                            $lt: material.data[0].attributes.publishedAt,
                        },
                    },
                    sort: 'publishedAt:desc',
                });

                if (prev.data && prev.data.length === 1) {
                    [this.prev] = prev.data;
                }

                const categories = material.data[0].attributes.categories;
                if (categories && categories.data.length) {
                    const indexCrumb = {
                        category_name: this.$t('materials.breadcrumbs.index'),
                        category_url_path: '/',
                        directLink: true,
                    };
                    const materialsCrumb = {
                        category_name: this.$t('materials.breadcrumbs.materials'),
                        category_url_path: '/materiaalit',
                        directLink: true,
                    };
                    const categoryCrumb = {
                        category_name: categories.data[0].attributes.label,
                        category_url_path: `/materiaalit?category=${categories.data[0].attributes.label}`,
                        directLink: true,
                    };
                    const materialTitle = material.data[0].attributes.title;

                    this.materialBreadCrumbs = {
                        crumbs: [indexCrumb, materialsCrumb, categoryCrumb],
                        product: materialTitle,
                    };
                }
            } else {
                this.$nuxt.error({ statusCode: 404, message: 'Material not found' });
                throw new Error('Material not found');
            }
        }
    },
    head() {
        const head = {};
        if (this.singleMaterial && this.singleMaterial.attributes.meta) {
            const metaFields = this.singleMaterial.attributes.meta.data.attributes;
            head.meta = [];
            if (metaFields?.title) {
                head.meta.push({
                    hid: 'title',
                    name: 'title',
                    content: metaFields.title,
                });
            }
            if (metaFields?.ogTitle) {
                head.meta.push({
                    hid: 'og:title',
                    name: 'og:title',
                    content: metaFields.ogTitle,
                });
            }
            if (metaFields?.ogDescription) {
                head.meta.push({
                    hid: 'og:description',
                    name: 'og:description',
                    content: metaFields.ogDescription,
                });
                head.meta.push({
                    hid: 'description',
                    name: 'description',
                    content: metaFields.ogDescription,
                });
            }
            if (metaFields?.keywords) {
                head.meta.push({
                    hid: 'keywords',
                    name: 'keywords',
                    content: metaFields.keywords,
                });
            }
            if (metaFields?.ogImage?.data?.attributes?.url) {
                head.meta.push({
                    hid: 'og:image',
                    name: 'og:image',
                    content: metaFields.ogImage.data.attributes.url,
                });
            }
        }

        if (this.singleMaterial) {
            head.title = this.singleMaterial.attributes.title;
        } else {
            head.title = this.$t('materials.title');
        }

        return head;
    },
    computed: {
        isIndexPage() {
            return this.route.toLowerCase().replaceAll('/', '') === 'materiaalit';
        },
    },
    watch: {
        '$route.query'() {
            this.$fetch();
        },
    },
    methods: {
        clearFilters() {
            this.filter = [];
            this.$fetch();
        },
        addToFilters(filterLabel) {
            this.filter = [filterLabel];
            this.$fetch();
        },
    },
};
