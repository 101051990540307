export function validatePassword(password) {
    const minimumLength = 8;
    if (password.length < minimumLength) {
        return false;
    }

    let characterCategories = 0;
    const minimumCategories = 3;

    const lowerCaseLetters = /[a-z]/g;
    if (password.match(lowerCaseLetters)) {
        characterCategories++;
    }

    const upperCaseLetters = /[A-Z]/g;
    if (password.match(upperCaseLetters)) {
        characterCategories++;
    }

    const numbers = /[0-9]/g;
    if (password.match(numbers)) {
        characterCategories++;
    }

    const specialChars = /[_@.#&+-]/g;
    if (password.match(specialChars)) {
        characterCategories++;
    }

    return characterCategories >= minimumCategories;
}

export function validateEmail(email) {
    return email.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}
