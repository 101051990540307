
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: false,
            default: '',
        },
        linkUrl: {
            type: String,
            required: false,
            default: '',
        },
        linkTitle: {
            type: String,
            required: false,
            default: '',
        },
    },
};
