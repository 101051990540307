import { render, staticRenderFns } from "./LayeredNavigation.vue?vue&type=template&id=f2565236&"
import script from "./LayeredNavigation.vue?vue&type=script&lang=js&"
export * from "./LayeredNavigation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryTreeMenu: require('/~/src/modules/opus-ecom/components/CategoryTreeMenu.vue').default,LayeredFilters: require('/~/src/modules/opus-ecom/components/LayeredFilters.vue').default,BreadCrumbs: require('/~/src/modules/opus-ecom/components/BreadCrumbs.vue').default,CategoryBanner: require('/~/src/components/CategoryBanner.vue').default,CategoryTree: require('/~/src/components/CategoryTree.vue').default,OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,LayeredSort: require('/~/src/modules/opus-ecom/components/LayeredSort.vue').default,ProductCard: require('/~/src/modules/opus-ecom/components/ProductCard.vue').default,OpusCard: require('/~/src/modules/opus/components/Card.vue').default,LayeredPagination: require('/~/src/modules/opus-ecom/components/LayeredPagination.vue').default,ProductList: require('/~/src/components/ProductList.vue').default,SEOText: require('/~/src/components/SEOText.vue').default,ProductPreview: require('/~/src/components/ProductPreview.vue').default,OpusModal: require('/~/src/modules/opus/components/Modal.vue').default})
