import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=048d99e6&"
import script from "./ProductCard.vue?vue&type=script&lang=js&"
export * from "./ProductCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductBadge: require('/~/src/components/ProductBadge.vue').default,HanselProductBadge: require('/~/src/components/HanselProductBadge.vue').default,ProductCardAddToCart: require('/~/src/modules/opus-ecom/components/Catalog/ProductCardAddToCart.vue').default,OpusCard: require('/~/src/modules/opus/components/Card.vue').default})
