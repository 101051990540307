import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=915cd2fe&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default})
