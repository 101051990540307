import { render, staticRenderFns } from "./LayeredSort.vue?vue&type=template&id=1f84aa85&"
import script from "./LayeredSort.vue?vue&type=script&lang=js&"
export * from "./LayeredSort.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusSelect: require('/~/src/modules/opus/components/Select.vue').default})
