
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        col() {
            const tableWidth = 12;
            const calculation = tableWidth / this.items.length;

            return `lg:col-span-${calculation}`;
        },
    },
};
