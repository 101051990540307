
export default {
    props: {
        filters: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selected: [],
        };
    },
    computed: {
        filteredSelectedFilters() {
            const blackList = ['sortType', 'currentPage', 'pageSize', 'sortDir'];

            return this.selected.filter((item) => {
                const [code] = item.split('-');

                return !blackList.includes(code);
            });
        },
        blackListed() {
            return this.filters.filter((item) => item.attribute_code !== 'category_uid' && !item.label.includes('_bucket'));
        },
        filterParams() {
            const filters = {};
            this.selected.forEach((item) => {
                const split = item.split('-');
                if (!filters[split[0]]) {
                    filters[split[0]] = [split[1]];
                } else {
                    const newFilter = filters[split[0]];
                    newFilter.push(split[1]);
                    filters[split[0]] = newFilter;
                }
            });

            if (this.$route.query.q) {
                filters.q = this.$route.query.q;
            }

            return filters;
        },
    },
    mounted() {
        this.$root.$on('quicksearch', this.removeAllFilters);

        Object.keys(this.$route.query).forEach((item) => {
            if (typeof this.$route.query[item] === 'string') {
                this.selected.push(`${item}-${this.$route.query[item]}`);
            } else if (typeof this.$route.query[item] === 'object') {
                this.$route.query[item].forEach((queryItem) => {
                    this.selected.push(`${item}-${queryItem}`);
                });
            }
        });
    },
    methods: {
        getFilterLabel(filter) {
            if (filter.label === 'Price') {
                return 'Hinta';
            }

            return filter.label;
        },
        getFilterNameAndValue(filter) {
            const [code, value] = filter.split('-');
            let valueLabel = null;
            const [text] = this.filters
                .filter((item) => item.attribute_code === code)
                .filter((item) =>
                    item.options.filter((option) => {
                        if (option.value === value) {
                            valueLabel = option.label;
                            valueLabel = valueLabel.includes('_') ? valueLabel.replace('_', ' - ') : valueLabel;
                        }

                        return option.value === value;
                    }),
                );

            return valueLabel;

            // eslint-disable-next-line no-unreachable
            return text ? `${text.label}: ${valueLabel}` : null;
        },
        removeFilter(filter) {
            this.selected.splice(this.selected.indexOf(filter), 1);
            this.updateFilter();
        },
        removeAllFilters() {
            this.selected = [];
            this.updateFilter();
        },
        updateFilter() {
            this.$scrollTo('#category-products');
            this.$router.push({
                path: this.$route.path,
                query: {
                    sortDir: this.$route.query.sortDir,
                    sortType: this.$route.query.sortType,
                    ...this.filterParams,
                    currentPage: 1,
                },
            });
        },
    },
};
