
export default {
    fetchOnServer: false,
    data() {
        return {
            heroBanners: [],
            bannerCard: null,
            highlightedProducts: null,
            callToActionBlocks: null,
            highlightedCategories: null,
            promoHalfCard: null,
            notificationBanner: null,
            productBrands: null,
        };
    },
    async fetch() {
        const heroBanners = await this.$strapi.getEntries({
            contentType: 'hero-banners',
            filters: {
                scope: {
                    $eq: '/',
                },
            },
            populate: ['image'],
        });
        this.heroBanners = heroBanners.data;

        const bannerCard = await this.$strapi.getEntries({
            contentType: 'banner-cards',
            populate: ['image', 'link'],
        });
        if (bannerCard.data && bannerCard.data.length >= 1) {
            [this.bannerCard] = bannerCard.data;
        }

        const highlightedProducts = await this.$strapi.getEntries({
            contentType: 'highlighted-products-lists',
            filters: {
                internalName: {
                    $eq: 'homePage',
                },
            },
            populate: ['link'],
        });
        if (highlightedProducts.data && highlightedProducts.data.length === 1) {
            [this.highlightedProducts] = highlightedProducts.data;
        }

        const callToAction = await this.$strapi.getEntries({
            contentType: 'call-to-actions',
            filters: {
                internalName: {
                    $eq: 'homePage',
                },
            },
            populate: ['items', 'items.link'],
        });
        if (callToAction.data && callToAction.data.length === 1) {
            [this.callToActionBlocks] = callToAction.data;
        }

        const highlightedCategories = await this.$strapi.getEntries({
            contentType: 'highlighted-categories-lists',
            filters: {
                internalName: {
                    $eq: 'homePage',
                },
            },
            populate: ['categoryCards', 'categoryCards.image'],
        });
        if (highlightedCategories.data && highlightedCategories.data.length === 1) {
            [this.highlightedCategories] = highlightedCategories.data;
        }

        const promoHalfCard = await this.$strapi.getEntries({
            contentType: 'promo-half-cards',
            populate: ['image', 'link'],
        });
        if (promoHalfCard.data && promoHalfCard.data.length >= 1) {
            [this.promoHalfCard] = promoHalfCard.data;
        }

        const notificationBanner = await this.$strapi.getEntries({
            contentType: 'notification-banners',
        });
        if (notificationBanner.data && notificationBanner.data.length >= 1) {
            [this.notificationBanner] = notificationBanner.data;
        }

        const productBrands = await this.$strapi.getEntries({
            contentType: 'product-brands-lists',
            filters: {
                internalName: {
                    $eq: 'homePage',
                },
            },
            populate: ['items', 'items.image'],
        });
        if (productBrands.data && productBrands.data.length === 1) {
            [this.productBrands] = productBrands.data;
        }
    },
    watch: {
        $route() {
            this.closeModal();
        },
    },
    methods: {
        async closeModal() {
            await this.$store.dispatch('catalog/close');
        },
    },
};
