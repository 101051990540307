
export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            sliderMinimumLinks: 4,
        };
    },
    computed: {},
    methods: {
        locateQuickLink(link) {
            if (link.startsWith('http')) {
                return link;
            }

            return this.localeRoute({ name: 'all', params: { pathMatch: link } });
        },
    },
};
