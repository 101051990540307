
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
        imageUrl: {
            type: String,
            required: false,
            default: '',
        },
        imageTitle: {
            type: String,
            required: false,
            default: '',
        },
        linkUrl: {
            type: String,
            required: false,
            default: '',
        },
        linkTitle: {
            type: String,
            required: false,
            default: '',
        },
        imageRight: {
            type: Boolean,
            required: false,
            default: true,
        },
        background: {
            type: String,
            required: true,
            default: 'Light',
        },
    },
    computed: {
        colors() {
            switch (this.background) {
                case 'Accent':
                    return 'bg-tertiary text-black';
                case 'Accent-Dark':
                    return 'bg-[#2D3D47] text-white';
                default:
                    return 'bg-white text-black';
            }
        },
    },
};
