
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
        imageUrl: {
            type: String,
            required: true,
        },
        imageTitle: {
            type: String,
            required: true,
        },
        linkUrl: {
            type: String,
            required: true,
        },
        linkTitle: {
            type: String,
            required: true,
        },
    },
};
