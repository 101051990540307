
export default {
    name: 'OpusCard',
    props: {
        rounded: {
            type: Boolean,
            default: true,
            required: false,
        },
        elevation: {
            type: Number,
            required: false,
            default: 1,
            validator(value) {
                /* eslint-disable no-magic-numbers */
                return [0, 1, 2, 3].includes(value);
            },
        },
        showOverflow: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    render(h) {
        const data = {
            class: {
                'bg-white transition-shadow': true,
                'overflow-hidden': !this.showOverflow,
                'rounded-lg': this.rounded,
                '': this.elevation === 0,
                shadow: this.elevation === 1,
                'shadow-lg': this.elevation === 2,
                'shadow-xl': this.elevation === 3,
                /* eslint-enable no-magic-numbers */
            },
        };

        return h('div', data, [
            this.$slots.header ? h('div', { class: { 'border-gray-200 border-b-2 p-4': true } }, this.$slots.header) : null,
            this.$slots.default,
            this.$slots.footer ? h('div', { class: { 'border-gray-200 border-t-2 p-4': true } }, this.$slots.footer) : null,
        ]);
    },
};
