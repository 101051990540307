import { render, staticRenderFns } from "./HomePage.vue?vue&type=template&id=0ad470b3&"
import script from "./HomePage.vue?vue&type=script&lang=js&"
export * from "./HomePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroBanner: require('/~/src/components/HeroBanner.vue').default,BannerCard: require('/~/src/components/BannerCard.vue').default,ProductList: require('/~/src/components/ProductList.vue').default,CallToAction: require('/~/src/components/CallToAction.vue').default,HighlightedCategories: require('/~/src/components/HighlightedCategories.vue').default,OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,RichText: require('/~/src/components/RichText.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default,Newsletter: require('/~/src/components/Newsletter.vue').default,ProductPreview: require('/~/src/components/ProductPreview.vue').default,OpusModal: require('/~/src/modules/opus/components/Modal.vue').default})
