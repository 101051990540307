
export default {
    props: {
        uid: {
            type: String,
            required: false,
            default: '',
        },
        product: {
            type: String,
            required: true,
        },
        crumbs: {
            type: Array,
            required: true,
        },
    },
};
