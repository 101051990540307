
export default {
    props: {
        sorts: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            open: false,
            options: [
                {
                    name: 'order-sort',
                    label: this.$t('catalog.sort.default'),
                    value: { sortType: 'position', sortDir: 'ASC' },
                },
                {
                    name: 'most-popular',
                    label: this.$t('catalog.sort.price.popular'),
                    value: { sortType: 'bestseller_score', sortDir: 'ASC' },
                },
                {
                    name: 'price-low-high',
                    label: this.$t('catalog.sort.price.lowhigh'),
                    value: { sortType: 'price', sortDir: 'ASC' },
                },
                {
                    name: 'price-high-low',
                    label: this.$t('catalog.sort.price.highlow'),
                    value: { sortType: 'price', sortDir: 'DESC' },
                },
            ],
        };
    },
    methods: {
        isActive(option) {
            try {
                return option.value.sortType === this.$route.query.sortType && option.value.sortDir === this.$route.query.sortDir;
            } catch (e) {
                return false;
            }
        },
        updateSort(filter) {
            this.$router.push({ path: this.$route.path, query: { ...this.$route.query, ...filter.value } });
        },
    },
};
