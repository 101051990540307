
import getRecentlyViewedProducts from '~/queries/getRecentlyViewedProducts.graphql';
import addRecentlyViewedProducts from '~/queries//mutations/addRecentlyViewedProducts.graphql';
export default {
    props: {
        urlKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            product: null,
            pimage: null,
            review: false,
            backPath: '/',
            groupedQty: null,
            selectedProduct: { uid: 'none' },
            recentlyViewed: null,
        };
    },
    async fetch() {
        const {
            data: {
                products: { items },
            },
        } = await this.$axios.$get(`/product/${this.urlKey}`);

        const [product] = items;
        if (!product) {
            this.$nuxt.error({ statusCode: 404, message: 'Product not found' });
            throw new Error('Product not found');
        }
        this.product = product;
        this.pimage = product.small_image.url;
        try {
            if (this.$store.state.auth.loggedIn) {
                try {
                    const {
                        data: { recentlyViewedProducts },
                    } = await this.$apollo.query({
                        query: getRecentlyViewedProducts,
                    });

                    if (recentlyViewedProducts && recentlyViewedProducts.items && recentlyViewedProducts.items.length) {
                        this.recentlyViewed = recentlyViewedProducts.items.map((item) => item.sku);
                    }
                } catch (e) {
                    console.error(e);
                }

                await this.$apollo.mutate({
                    mutation: addRecentlyViewedProducts,
                    variables: {
                        input: [this.product.uid],
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }

        if (this.$gtm) {
            try {
                this.$gtm.push({ ecommerce: null, items: null });
                this.$gtm.push({
                    event: 'view_item',
                    ecommerce: {
                        currency: this.product.price_range.minimum_price.final_price.currency,
                        items: [
                            {
                                item_id: this.product.sku,
                                item_name: this.product.name,
                                price: this.product.price_range.minimum_price.final_price.value,
                            },
                        ],
                    },
                    value: this.product.price_range.minimum_price.final_price.value,
                    items: [
                        {
                            id: this.product.sku,
                            google_business_vertical: 'retail',
                        },
                    ],
                });
            } catch (e) {
                console.error(e);
            }
        }
    },
    head() {
        function starCount(items) {
            const len = [...items].length;
            const total = [...items].reduce((acc, curr) => {
                const conversionRateFromPercentage = 20;
                const rating = curr.average_rating / conversionRateFromPercentage;

                return acc + rating;
            }, 0);

            return parseInt((total / len).toFixed(0)) || 0;
        }

        if (!this.product) {
            return;
        }
        const reviews = this.product.reviews.items;
        const review = reviews.reduce((acc, curr) => {
            const obj = {};
            obj['@type'] = 'Review';
            obj.author = {
                '@type': 'Person',
                name: curr.nickname,
            };
            obj.name = curr.summary;
            obj.description = curr.text;
            const ratingsBreakdown = curr.ratings_breakdown.map((review) => Number(review.value));
            const preciseLevel = 3;
            obj.reviewRating = {
                '@type': 'Rating',
                ratingValue: (ratingsBreakdown.reduce((a, b) => a + b, 0) / ratingsBreakdown.length).toFixed(preciseLevel),
                bestRating: '5',
                worstRating: '0',
            };

            acc.push(obj);

            return acc;
        }, []);

        const aggregateRating =
            this.product.review_count > 0
                ? {
                      '@type': 'AggregateRating',
                      ratingValue: starCount(this.product.reviews.items),
                      ratingCount: this.product.review_count,
                      bestRating: '5',
                      worstRating: '0',
                  }
                : null; // Google accepts only positive value in ratingCount

        const structuredDataScript =
            review.length > 0 || this.product.review_count > 0
                ? [
                      {
                          type: 'application/ld+json',
                          json: {
                              '@context': 'https://schema.org',
                              '@type': 'Product',
                              name: this.product.name,
                              description: !this.$fetchState.pending && !this.$fetchState.error && this.shortDescriptionText,
                              image: this.product.thumbnail.url,
                              review,
                              aggregateRating,
                          },
                      },
                  ]
                : [];
        // Google requires 'Either "offers", "review", or "aggregateRating" should be specified',
        // so if no reviews or rating - don't add structured data

        return {
            title: this.product.name,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: !this.$fetchState.pending && !this.$fetchState.error && this.shortDescriptionText,
                },
                {
                    hid: 'og:description',
                    name: 'og:description',
                    content: !this.$fetchState.pending && !this.$fetchState.error && this.shortDescriptionText,
                },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.product.name,
                },
                {
                    hid: 'og:image',
                    name: 'og:image',
                    content: !this.$fetchState.pending && !this.$fetchState.error && this.product.thumbnail.url,
                },
            ],
            link: [
                { rel: 'preload', as: 'image', href: this.product.thumbnail.url },
                { rel: 'canonical', href: `${this.$config.HOSTNAME}/${this.urlKey}` },
            ],
            script: structuredDataScript,
        };
    },
    computed: {
        shortDescriptionText() {
            return this.product.short_description.html.replace(/(<([^>]+)>)/gi, '');
        },
        manufacturer() {
            if (this.product) {
                const value = this.product.custom_attributes.find((attribute) => attribute.attribute_metadata.code === 'manufacturer');

                return value?.selected_attribute_options?.attribute_option[0].label;
            }

            return '';
        },
        /**
         * Find the breadcrumbs that has the most categories
         * @returns {*|*[]}
         */
        category() {
            if (this.product) {
                let len = 0;
                let result = null;
                [...this.product.categories].forEach((item) => {
                    if (item.breadcrumbs?.length && len < item.breadcrumbs?.length) {
                        len = item.breadcrumbs?.length;
                        result = item;
                    }
                });

                return result ? result.breadcrumbs : [];
            }

            return [];
        },
        crossSellProducts() {
            return this.product.crosssell_products && this.product.crosssell_products.length;
        },
    },
    created() {
        if (this.$nuxt?.context?.from?.path) {
            this.backPath = this.$nuxt.context.from.path;
        }
    },
    methods: {
        scrollToDescription() {
            this.$scrollTo('#description-section');
        },
        setSelectedProduct(item) {
            this.selectedProduct = item && item.product && item.media_gallery ? item.product : { uid: 'none' };
        },
    },
    fetchKey: 'product-page',
};
